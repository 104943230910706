.title {
    font-family: "Garamond";
    color: white;
    font-size: 20pt;
}

.subtitle {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16pt;
    color: #ffffff;
}

#txtBountyAmount {
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 10px;
    /* background-color:black;
    color: white; */
    font-weight: bold;
    font-size: large;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    width: 200px;
    
    border-radius: 30px;
    height: 50px;
    padding-left: 15px;

    resize:none;
    padding-top:10px;
}

#txtSearch {
    font-family: Arial, Helvetica, sans-serif;
}

.controlLabel {
    color: white;
}

#modalTitle:before {
    background: url("https://firebasestorage.googleapis.com/v0/b/slictionary-test-2b1f1.appspot.com/o/information-icon-orig.png?alt=media&token=467f966b-996c-48ab-943e-8fdd6f533a2c") no-repeat scroll center center / 100% auto rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;/*flex;*/ /* inline-block; */
    color: #fff;
    height: 40px;
    margin-right: 12px;
    margin-bottom: 1px;
    position: relative;
    vertical-align: middle;
    text-align: left;
    width: 40px;
}

.react-numeric-input{
    width: 100px;
    font-family: Arial, Helvetica, sans-serif;
    position: relative;
    left: 90px;
}

.form-control{
    font-size: 12pt;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
}

.word-bounty {
    font-size: 14pt;
    text-align: center;
    align-items: center;
    align-content: center;
    color: #fafad2;
}

ul {
    padding-left: 15px;
}

#txtContinue, #txtOK {
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}