.walletProviderItem {
    color: white;
}

.dropdown-menu:before {
    position: absolute;
    top: -6px;
    left: 10px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
}
  
.dropdown-menu:after {
    position: absolute;
    top: -6px;
    left: 10px;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    border-left: 6px solid transparent;
    content: '';
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
#connectButton{
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    font-family: 'Poppins', sans-serif;
    box-shadow: 0px 1px 3px hsla(0, 0%, 0%, .15);
    background-image: linear-gradient(#38CB7C, #1CB462);
    border-radius: 8px;
    display:inline-block;
    text-align: center;
    cursor:pointer;
    color:#ffffff;
    font-size:16px;
    font-weight:500;
    padding:16px 24px;
    text-decoration:none;
    transition: 0.3s;
    width: 100%;
    max-width: 320px;
    vertical-align: middle;
    letter-spacing: 0.5px;
}
#connectButton:before {
    background: url("https://files.readme.io/8f31c8a-ic_stat_handcash_notification.png") no-repeat scroll center center / 100% auto rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;/*flex;*/ /* inline-block; */
    color: #fff;
    height: 20px;
    margin-right: 12px;
    margin-bottom: 1px;
    position: relative;
    vertical-align: middle;
    text-align: left;
    width: 20px;
}
/* #connectButton:hover {
    background-image: linear-gradient(#31C475, #16B15D);
    top:1px;
    box-shadow: 0px 3px 6px hsla(0, 0%, 0%, .15);
} */
#connectButton:active {
    background-image: linear-gradient(#38CB7C, #1CB462);
    position:relative;
    top:1px;
    box-shadow: 0px 0px 0px hsla(0, 0%, 0%, .15);
}

#aLogin {
    font-family: "Oswald";
    font-size: 16px;
    color: #a4c2f4;
    text-align: right;
}

.blueLogout {
    font-family: "Oswald";
    font-size: 16px;
    color: rgb(71, 114, 246); 
    /* #4772F6 */
    text-align: right;

}

.greenLogout {
    font-family: "Oswald";
    font-size: 16px;
    color: #38CB7C;
    text-align: right;

}