*{
    margin: 0;
    padding: 0;
  }
  
  .heading{
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 1rem;
    font-weight: bold;
  }

  #btnAddWord {
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    font-family: 'Poppins', sans-serif;
    box-shadow: 0px 1px 3px hsla(0, 0%, 0%, .15);
    background-image: linear-gradient(#38CB7C, #1CB462);
    border-radius: 8px;
    display:inline-block;
    text-align: center;
    cursor:pointer;
    color:#ffffff;
    font-size:16px;
    font-weight:500;
    padding:16px 24px;
    text-decoration:none;
    transition: 0.3s;
    width: 250px;
    max-width: 320px;
    vertical-align: middle;
    letter-spacing: 0.5px;
  }
  
  #btnAddWord:before {
        background: url("https://files.readme.io/8f31c8a-ic_stat_handcash_notification.png") no-repeat scroll center center / 100% auto rgba(0, 0, 0, 0);
        content: "";
        display: inline-block;/*flex;*/ /* inline-block; */
        color: #fff;
        height: 20px;
        margin-right: 12px;
        margin-bottom: 1px;
        position: relative;
        vertical-align: middle;
        text-align: left;
        width: 20px;
  }
  
  #input[type="file"] {
      display: none;
  }

  label{
    font-size:16pt;
}

  .label{
    width: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }

  .image-upload{
    margin: auto;
    width: 200px;
    height: 50px;
    color: white;
    border-radius: 10px;
    background-color: transparent;
    text-align: center;
    cursor: pointer;
  }

  .fade-in-text {
    font-family: Arial;
    font-size: 150px;
    animation: fadeIn 5s;
  }

  /* fallback */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v55/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
  }
  
  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -moz-font-feature-settings: 'liga';
    -moz-osx-font-smoothing: grayscale;
  }

  .pointer{
    cursor:pointer;
}
