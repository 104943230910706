#audioDropDown{
    width: 200px;
    display: inline-flex;
}
.inline{
    display: inline-flex;
    white-space: nowrap;
    margin-right: 5px;
    margin-left: 5px;
}
#title{
    margin-right:20px;
}
.dropdown-toggle::after {
    display: none;
}
.dropdown-menu{
    background-color: black;
}

/* .dropdown-item {
    background-color: black;
    text-decoration: none; 
}*/

.hidden{
    display: none;
}
a:visited{
    color:none !important;
}
a:link {
    color: none !important;
    text-decoration:none !important;
}
.dropdown-item.active {
    /* color: blue; */
    background-color: #007bff !important;
}

p {
    color: white;
}