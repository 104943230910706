.modal-content {
    box-shadow: 0 0 0 1px white;
    border: none;
  }

.redeemModal {
    border-color: white;
}

th,td {
    color:white;
}
.slick-slider {
    width: 100%;
    }

    .slick-list {
        margin: 0 -240px;
      }

.slick-prev {
    margin: 0 -275px;
}

.slick-next {
    right: -300px;
    height: 50px;
    width: 50px;    
  }

.audio{
  background:black;
  font-size:13pt;
  width:318px;
  color:white;
  display: inline-block;
}
.definitionTop { 
    background:black;
    font-size:13pt;
    width:318px;
    color:white;
    border-color: darkgray;
    display: inline-block;
    border-style: solid;
    border-width: 1px;
    /* border-bottom: ; */
    border-radius: 15px;  
    padding-left:10px;
    padding-right:10px;
    padding-top:10px;
    padding-bottom:10px;
}
p:hover{
  color:white;
}

p {
    color: white;
}

img:hover{
  filter: brightness(100%);
}
.definitionBottom { 
  background:black;
  font-size:11pt;
  width:318px;
  color:white;
  border-color: darkgray;
  display: inline-block;
  border-style: solid;
  border-width: 1px;
  /* border-radius: 10px; */
  padding-left:10px;
  padding-right:10px;
  padding-top:10px;
  padding-bottom:10px;
  border-top:none;
}

.imagePanel{
  width:100%;
  height:100%;
  background:black;
  font-size:11pt;
  /* width:318px; */
  color:white;
  display: block; /*inline-block;*/
  padding-left:5px;
  margin-left: auto;
  margin-right: auto;
}

img.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

button.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  
}

.row {
    display: flex;
}

.column {
    flex: 50%;
    padding: 3px;
    top: 0px;
  }

  .far {
    color:white;
    
  }
  .far:hover {
    color:blue;
  }

  .fa {
    color:white;
    
  }
  .fa:hover {
    color:blue;
  }

  i:hover{
      color:white;
  }

  .modal-image{
    max-height: 150px !important;
  }

  /* .modal-dialog {
      position: relative;
      width: auto;
      margin: .5rem;
      pointer-events: none;
      border: solid;
      border-color: darkgray;
  } */

  .brightness-100{
    filter: brightness(100%);
  }
  .brightness-80{
    filter: brightness(100%);
  }
  .brightness-60{
    filter: brightness(100%);
  }
  .brightness-40{
    filter: brightness(100%);
  }
  .brightness-20{
    filter: brightness(100%);
  }
  .brightness-10{
    filter: brightness(100%);
  }
  .brightness-5{
    filter: brightness(100%);
  }

  #leftArrow {
    /* background-image: url("/static/media/Arrow-feather-left-white.66ca82a6.png"); */
    position: relative;
    left: -355px;
    width: 30%;
    /* height: 15%; */
    top: 250px;  
  }

  #rightArrow {
    position: absolute;
    left: 550px;
    width: 30%;
    /* height: 20%; */
    top: 250px;  
  }
