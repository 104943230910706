release-h1 {
    font-size: 36pt;
    color: lightgoldenrodyellow;
    /* padding: 10px; */
}

.release-h2{
    font-size: 24pt;
    color: lightgoldenrodyellow;
    padding-bottom: 20px;
    text-align: left;
}

.release-h3 {
    font-size: 20pt;
    color: lightgoldenrodyellow;
    padding-bottom: 0px;
    text-align: left;
    width: 750px;
}

.release-p {
    font-size: 14pt;
    width: 750px;
    text-align: left;
    padding-bottom: 20px;
    padding-top: 20px;
    color: white;
}

