body{
  background-image: url('./assets/Word Vortex.gif');
  background-size: cover;
  height: '100vh';
  padding: '0';
  margin: '0';
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "sans-serif";
  background-color:black;
}

@font-face {
  font-family: 'Trocadero';
  src: url('./fonts/trocadero.regular.ttf')  format('truetype') 
} 

@font-face {
  font-family: 'Oswald';
  src: url('./fonts/Oswald-Regular.ttf')
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


#col-5::after{
  opacity: 0;
}

.logo{
  height: 50px;
}

#word-bounty{
  white-space: nowrap;
  margin-left: 80px; 
  text-align: right;
  font-family: "Arial";
   color:yellow;/*#39FF14; lightgreen;*/
  font-size: 24pt;
   text-decoration: underline;

}

a:link {
  color: lightgoldenrodyellow;
}

/* visited link */
a:visited {
  color: lightgoldenrodyellow;
}

/* mouse over link */
a:hover {
  color: #125699;
}

/* selected link */
a:active {
  color: lightgoldenrodyellow;
} 

#leaderboard, #myslictionary, #support {
  font-family: "Oswald";
  font-size: 16px;
  color: #a4c2f4;
  text-align: right;
}


.fa {
  color:yellow;
  
}
.fa:hover {
  color:blue;
}
.fa1:hover{
  color: yellow;
}
.far {
  color:white;
  
}
.far:hover {
  color:blue;
}
.content {
    display: flex;
    margin: 32px auto;
    padding: 0 16px;
    max-width: 960px;
    flex-direction: column;
    align-items: center;
    background-color: black; 
}
.container section:first-child {
  display: flex;
  align-items: flex-start; /* new */
  margin-bottom: 25px;
}
.container img {
  width: 500px;
  height: auto;
}

* {
    box-sizing: border-box;
  }
  *:before,
  *:after {
  box-sizing: border-box;
  }

html,
body {
  height: 100%;
  position: relative;
}
#mainContainer {
  min-height: 100vh; /* will cover the 100% of viewport */
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 100px; /* height of your footer */
}
#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

#divFooter{
    position: absolute;
    bottom: 0;
    width: 100%;
  
}

#login {
    background-color:"black";
    font-size:"10pt";
  }

  .disabled-link {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  }

.p-about {
    text-align: left;
    margin-top: 10px;   
   
}