.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
  }
  
  #rowLookAhead{
      top:-50px;
      left: 6px;
  }
  
  #imgEnglishSite {
      position: absolute;
      top: 65px;
      left:275px;
      height: 37px;
      width: 37px;
  }
  /* #btnWeave {
      position: relative;
      left: -15px;
  } */
  #btnWordBounty {
    position: absolute;
    top: 65px;
    left: 125px;
    height: 37px;
    width: 37px;
    border-radius: 50%;
    font-weight: bold;
    display: inline-block;
    font-size: 16pt;
    color: rgb(30, 31, 30);
    background-color: white;
    text-align: center;
    vertical-align: middle;
}
  #becomeabaron {
      position: absolute;
      top: 65px;
      left: 200px;
      height: 37px;
      width: 37px;
      border-radius: 50%;
      display: inline-block;
      font-size: 18pt;
      color: rgb(30, 31, 30);
      background-color: white;
      text-align: center;
      vertical-align: middle;
  }
  #slebrity {
    position: absolute;
    top: 65px;
    left: 350px;
    height: 37px;
    width: 37px;
    border-radius: 50%;
    display: inline-block;
    font-size: 18pt;
    color: green;
    background-color: white;
    text-align: center;
    vertical-align: middle;
}
  #btnSeek {
      position: relative;
      left:520px;
      top: -50px;
  }
  #iAddWord {
      position: absolute;
      left: 50px;
      top: 65px;
  }
  
  .App-header {
    background-color: black;/* #ffffe0;*/
    
    /*
    min-height: 100vh;
    
    display: flex;
    flex-direction: column;
    */
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #09d3ac;
  }
  
  .lightgreen {
    color: #06f27c;
  }
  
  #txtSearch{
    vertical-align: top;
  }
  
  .top-buffer { margin-top:200px; }
  
  input.rounded {
  
    border: 1px solid #ccc;
     height: 50px;
    /* Safari 5, Chrome support border-radius without vendor prefix.
     * FF 3.0/3.5/3.6, Mobile Safari 4.0.4 require vendor prefix.
     * No support in Safari 3/4, IE 6/7/8, Opera 10.0.
     */
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    
    /* Chrome, FF 4.0 support box-shadow without vendor prefix.
     * Safari 3/4/5 and FF 3.5/3.6 require vendor prefix.
     * No support in FF 3.0, IE 6/7/8, Opera 10.0, iPhone 3.
     * change the offsets, blur and color to suit your design.
     */
    -moz-box-shadow: 2px 2px 3px #666;
    -webkit-box-shadow: 2px 2px 3px #666;
    box-shadow: 2px 2px 3px #666;
    
    /* using a bigger font for demo purposes so the box isn't too small */
    font-size: 20px;
    
    /* with a big radius/font there needs to be padding left and right
     * otherwise the text is too close to the radius.
     * on a smaller radius/font it may not be necessary
     */
    padding: 4px 7px;
    
    /* only needed for webkit browsers which show a rectangular outline;
     * others do not do outline when radius used.
     * android browser still displays a big outline
     */
    outline: 0;
  
    /* this is needed for iOS devices otherwise a shadow/line appears at the
     * top of the input. depending on the ratio of radius to height it will
     * go all the way across the full width of the input and look really messy.
     * ensure the radius is no more than half the full height of the input, 
     * and the following is set, and everything will render well in iOS.
     */
    -webkit-appearance: none;
    
  }
  
  input.rounded:focus {
    
    /* supported IE8+ and all other browsers tested.
     * optional, but gives the input focues when selected.
     * change to a color that suits your design.
     */
    border-color: #339933;
    
  }
  
  .toolbar {
    height: 60px;
    
    margin: -8px;
    /*
    display: flex;
    */
    align-items: center;
    background-color: black;
    color: white;
    font-weight: 600;
  }
  
  .logo{
    height: 60px;
    align-items: center;
    background-color: black;
    color: white;
    font-weight: 600;
  }
  
  .liveimage{
      cursor:pointer;
  }
  
  :host {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  h1{text-align: center; font-size: 3em; text-transform: uppercase;}
  
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 8px 0;
  }
  
  h4,h3,h2{
    color:white;
  }
  
  p {
    margin: 0;
  }
  
  .spacer {
    flex: 1;
  background-color: black;/* #ffffe0;*/
  }
  
  .toolbar {
    height: 60px;
    margin: -8px;
    display: flex;
    align-items: center;
    background-color: black;
    color: white;
    font-weight: 600;
  }
  
  .toolbar img {
    margin: 0 16px;
  }
  
  .toolbar #twitter-logo {
    height: 40px;
    margin: 0 16px;
  }
  
  .toolbar #twitter-logo:hover {
    opacity: 0.8;
  }
  
  .content {
    display: flex;
    margin: 32px auto;
    padding: 0 16px;
    max-width: 960px;
    flex-direction: column;
    align-items: center;
  background-color: black; /*#ffffe0;*/
  }
  .container section:first-child {
    display: flex;
    align-items: flex-start; /* new */
    margin-bottom: 25px;
  }
  .container img {
    width: 500px;
    height: auto;
  }
  
  .card.card.highlight-card span {
    margin-left: 60px;
  }
  
  .weave {
      margin:0px;
      margin-left:10px;
      width:150px;
      z-index: 1;
      color: rgb(129, 190, 247);
      border-radius: 10%;
      display: inline;
  
      border-radius: 10px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      
      border-radius: 30px;
      height: 50px;
      padding-left: 15px;
  
      resize:none;
      padding-top:10px;
  
      
  
  }
  
  .terminal {
    position: relative;
    width: 80%;
    max-width: 600px;
    border-radius: 6px;
    padding-top: 45px;
    margin-top: 8px;
    overflow: hidden;
    background-color: rgb(15, 15, 16);
  }
  
  .terminal::before {
    content: "\2022 \2022 \2022";
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    background: rgb(58, 58, 58);
    color: #c2c3c4;
    width: 100%;
    font-size: 2rem;
    line-height: 0;
    padding: 14px 0;
    text-indent: 4px;
  }
  
  .terminal pre {
    font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
    color: white;
    padding: 0 1rem 1rem;
    margin: 0;
  }
  
  
  .github-star-badge {
    color: #24292e;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 3px 10px;
    border: 1px solid rgba(27,31,35,.2);
    border-radius: 3px;
    background-image: linear-gradient(-180deg,#fafbfc,#eff3f6 90%);
    margin-left: 4px;
    font-weight: 600;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  }
  
  .github-star-badge:hover {
    background-image: linear-gradient(-180deg,#f0f3f6,#e6ebf1 90%);
    border-color: rgba(27,31,35,.35);
    background-position: -.5em;
  }
  
  .github-star-badge .material-icons {
    height: 16px;
    width: 16px;
    margin-right: 4px;
  }
  
  
  .searchFrame {
   
    transform: translate(-120px, 0px);
    z-index: 1;
    color:black;
    } 
  
    * {
      box-sizing: border-box;
    }
  
  
    #txtSearch{
  
      border-radius: 10px;
      background-color:black;
      color: white;
      font-weight: bold;
      font-size: large;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      /* width: 50; */
      
      border-radius: 30px;
      height: 50px;
      padding-left: 15px;
  
      resize:none;
      padding-top:10px;
  
    }
  
    
    #myInput {
      background-image: url('/css/searchicon.png');
      background-position: 10px 12px;
      background-repeat: no-repeat;
      width: 100%;
      font-size: 16px;
      padding: 12px 20px 12px 40px;
      border: 1px solid #ddd;
      margin-bottom: 12px;
    }
    
    #myUL {
  
      list-style-type: none;
      padding: 0;
      margin: 0;
      border: 1px solid #ddd;
      border-top: 1px solid #ddd;
      margin-top: -5px;
      margin-left: 50px;
      display: none;
      width:59%;
      overflow-x: hidden;
      height: 250px;
      
    }
    
  /* link that solved it: https://stackoverflow.com/questions/17824810/in-css3-is-lihover-a-and-li-ahover-the-same */
  /* was difficult to find the appropriate selector.*/
  
    ul#myUL li:hover{
      color: black;
    }
  
    .myUL-position-handcash {
      position: relative;
      top:35px; 
      left:-15px;
    }
  
    .myUL-position-moneybutton {
      position: relative;
      top:0px; 
      left:-15px;
    }
  
    #myUL li {
      
      margin-top: -1px; /* Prevent double borders */
      background-color: black;
      padding: 12px;
      text-decoration: none;
      font-size: 18px;
      color: white;
      display: block
    }
    
    #myUL li:hover:not(.header) {
      background-color: #eee;
    }
  
    #iAddWord{
      margin-top: 0px;
    }
  
  
        textarea:focus, input:focus{
          outline: none;
      }