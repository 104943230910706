.slebrity-h1 {
    color: lightgoldenrodyellow;
    font-family: Arial, Helvetica, sans-serif;
    font-size:30pt;
    text-align: center;
    text-transform: none;
}

.slebrity-h2 {
    color: lightgoldenrodyellow;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20pt;
    text-align: center;
    text-transform: none;
}

.slebrity-h3 {
    color: lightgoldenrodyellow;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14pt;
    text-align: center;
    text-transform: none;
}

.slebrity-submit {
    width: 150px;
    height: 50px;
    background-color: blue;
    border-color: blue;
    border-radius: 25px;
}

#numEnterMaxBid{
    height: 38px;
}

#auction-bid-cancel {
    width: 150px;
    height: 50px;
    color:white;
    background-color: black;
    border-color: white;
    border-radius: 30px;
    padding-left: 10px; 
    /* left: -13px;  */
    position: relative; 
    /* z-index: 99;  */
    top: -10px;
}

.slebrity-span {
    color: white;
}

.row {
    color: white;
}

#yourBid {
    background-color: white;
    border-radius: 30px; 
    position: relative; 
    left: -20px;
    text-align: right; 
    padding-right: 15px; 
    width: 125px;
}

.dollarSign {
    font-size: 18pt;
    padding-right: 10px;
    position: relative;
    left: -15px;
}

.horizontal_line {
    background-color: white;
    width: 100%;
    height: 1px;
    /* border-top: 5px solid black; */
    line-height: 80%;
}

.line {
    border-bottom: 5px solid red;
    margin-top: 5px;
    width: 90%;
}

#txtNominationName{
    border-radius: 10px;
    padding-left: 10px;
    width: 200px;
    height: 35px;
}
#txtWordToDefine{
    border-radius: 10px;
    padding-left: 10px;
    width: 200px;
    height: 35px;

}
#txtNominationAmount{
    border-radius: 10px;
    padding-left: 10px;
    width: 100px;
    height: 35px;

}

#btnUpdateMaxBid {
    border-radius: 50px;
    margin-top: 0px;
    vertical-align: top;
}
/* SLebrity Menu */

.tabButton {
    width: 205px;
}

.tooltip-inner {
    background-color: white;
    color: black;
    text-align: left;
}
.tooltip.bs-tooltip-right .arrow:before {
    border-right-color: white;
}
.tooltip.bs-tooltip-left .arrow:before {
    border-left-color: white;
}
.tooltip.bs-tooltip-bottom .arrow:before {
    border-bottom-color: white;
}
.tooltip.bs-tooltip-top .arrow:before {
    border-top-color: white;
}