.bountyPanel{
    white-space: nowrap;
    display: inline-block;
    background-color: lightgray;
    max-width: 500px;
    width:480px;
    border: 3px solid darkslategray;
    color: green;
    text-align: center;
    vertical-align: top;
    height: 100%;
}

.bountyPanelInner{
    margin:5px;
    text-align: center;
}

.labelText {
    color: black;
}

td {
    vertical-align: top;
}

h3{
    color: lightgoldenrodyellow;
}

h4{
    color:black;
}

.greenText{
    color:green;
}

.blackText{
    color:black;
}

.heading{
    font-size: 15pt;
    font-weight: bold;
}

.luckyYou{
    font-family: "Arial, Helvetica, sans-serif";
    color: lightgoldenrodyellow;
    font-size: 28pt;}

#addWord:hover {
    color: black;
}

#addWord{
    color:blue;
}

button:active {
    background: "#f2f2f2"
}